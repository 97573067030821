<template>
  <div>
    <!-- 위험등록부 목록 -->
    <c-table
      ref="tableRisk"
      title="위험등록부 목록"
      tableId="riskRegister01"
      :columns="gridRisk.columns"
      :data="riskReduce.riskbooks"
      :merge="gridRisk.merge"
      gridHeightAuto
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
    >
      <template v-slot:customArea="{ props, col }">
        <component
          :is="imprComponent"
          :col="col"
          :props="props"
          :inputEditable="false"
          :requestContentsCols="requestContentsCols"
          tableKey="ramKpsrAssessScenarioId"
          ibmTaskTypeCd="ITT0000005"
          ibmTaskUnderTypeCd="ITTU000005"
          @imprChange="imprChange"
        />
      </template>
    </c-table>
    <!-- 개선 목록 -->
    <c-table
      ref="tableImpr"
      title="개선 목록"
      tableId="impr01"
      :columns="gridImpr.columns"
      :data="riskReduce.imprs"
      :merge="gridImpr.merge"
      gridHeightAuto
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      @linkClick="linkClick"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'kpsr-risk-register-TL',
  props: {
    param: {
      type: Object,
      default: function() {
        return {
          ramRiskAssessmentPlanId: '',
          ramStepCd: '',
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      riskReduce: {
        imprs: [],
        riskbooks: [],
      },
      gridRisk: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'processDiagram' },
          { index: 2, colName: 'processDiagramNode' },
          { index: 3, colName: 'processDiagramNodeRisk' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            // 도면
            label: '도면명',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeCd',
            // 노드
            label: '노드',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'riskTypName',
            field: 'riskTypName',
            // 위험형태
            label: '위험형태',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: '원인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            // 결과
            label: '결과',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: '관련법규',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'relationProblem',
            field: 'relationProblem',
            // 관련문제사항
            label: '관련문제사항',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: '현재안전조치',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: '평가일',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: '평가자',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:130px',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improvementMeasures',
                field: 'improvementMeasures',
                // 개선대책
                label: '개선대책',
                align: 'left',
                style: 'width:200px',
                sortable: false
              },
            ]
          },
        ],
        data: [],
        height: '500px'
      },
      gridImpr: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'processDiagram' },
          { index: 2, colName: 'processDiagramNode' },
          { index: 3, colName: 'processDiagramNodeRisk' },
          { index: 4, colName: 'ramKpsrAssessScenarioId' },
          { index: 5, colName: 'ramKpsrAssessScenarioId' },
        ],
        columns: [],
        height: '500px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      requestImprRow: null,
      imprData: null,
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 20) + 'px' : '500px'
    },
    requestContentsCols() {
      return ['riskTypName', 'riskOccurrenceCause'];
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.ram.kpsr.riskReduce.list.url;
      // code setting
      this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
        this.gridImpr.columns = [
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            // 도면
            label: '도면명',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            // 노드
            label: '노드',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'riskTypName',
            field: 'riskTypName',
            // 위험형태
            label: '위험형태',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: '원인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            // 결과
            label: '결과',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: '관련법규',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'ibmStepCd',
            field: 'ibmStepCd',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            type: 'tag',
            colorItems: _result,
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            // 요청부서정보
            label: '요청부서정보',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            // 조치완료요청일
            label: '조치완료요청일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ]
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.$_.extend(this.riskReduce, _result.data);
      },);
    },
    imprChange() {
      this.$refs['tableRisk'].$refs['compo-table'].resetVirtualScroll();
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    openImpr(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup2;
    },
    closeImprPopup2(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['tableRisk'].$refs['compo-table'].resetVirtualScroll();
      }
    },
  }
};
</script>